import React from 'react';
import RatingPWA from 'containers/RatingPWA/PopupRating';
import car from '../../../assets/car.png';

export default function CarWaitingLayout() {
  return (
    <div className="h-[100vh] min-h-[500px] w-full flex flex-col  bg-black !text-white">
      <div className="h-full flex flex-col gap-11 px-7 justify-center">
        <p className="text-[43px] !text-[bold]" style={{ fontWeight: 'bolder', lineHeight: '1.25' }}>
          YOUR CAR
          <br />
          IS WAITING
          <br />
          FOR YOU…
        </p>
        <p className="text-sm" style={{ fontWeight: 'bold' }}>
          Thank you for using QuickBill ! Please
          <br />
          Don't be late for your car!
        </p>
        <RatingPWA />
      </div>

      <img className="mt-auto" src={car} alt="" />
    </div>
  );
}
