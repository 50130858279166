import { Axios } from "./Axios";

interface PaymentRequest {
    sessionId: string;
    outletRef: string;
    order: {
        action: 'SALE' | 'PURCHASE' | 'AUTH'
        amount: { currencyCode: string, value: number }
    }
}

interface Payment {
    total_payed: number;
    id_table: string | undefined;
    idPointSale: string | undefined;
    tip: number;
}
interface OneTimePaymentRequest {

    order: {
        action: 'SALE' | 'PURCHASE' | 'AUTH'
        amount: { currencyCode: string, value: number }
    }
    cardInformation: any
}

interface Order {
    action: 'SALE' | 'PURCHASE' | 'AUTH'
    amount: { currencyCode: string, value: number; }
    tableId: string | undefined;
    emailAddress: string
}
export const sendPaymentRequest = async (body: PaymentRequest) => {
    try {
        const res = await Axios.post(`/hosted-sessions/payment`, body);
        return res.data;
    }
    catch (e: any) {
        return e;
    }
};
export const sendOneStagePaymentRequest = async (body: OneTimePaymentRequest) => {
    try {
        const res = await Axios.post(`/hosted-sessions/payment/card`, body);
        return res.data;
    }
    catch (e: any) {
        return e;
    }
};
export const createOrder = async (body: Order) => {
    try {
        const res = await Axios.post(`/create-order`, body);
        return res.data;
    }
    catch (e: any) {
        return e;
    }
};
export const createPayment = async (body: Payment) => {
    try {
        const res = await Axios.post(`/payments`, body);
        return res.data;
    }
    catch (e: any) {
        return e;
    }
};

export const getOrderStatus = async (ref: string) => {
    try {
        const res = await Axios.post(`/order-status`, { ref, id_table: localStorage.getItem("idTable"), tip: localStorage.getItem('tip') });
        return res.data;
    } catch (e: any) {
        return e;
    }
};