import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import MainContext from 'contexts/rootContext';
import React, { useContext, useState } from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { BsEmojiAngry, BsEmojiFrown, BsEmojiLaughing, BsEmojiNeutral, BsEmojiSmile } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSendReview } from 'requests/Tickets/request';

export default function RatingPWA() {
  const history = useHistory();
  const [progress, setProgress] = useState(1);
  const [comment, setcomment] = useState<string>('');
  const { currentOrder } = useContext(MainContext);
  const [SendReview, { loading }] = useSendReview();

  const handleReview = () => {
    if (currentOrder?.id) {
      SendReview({ variables: { idOrder: currentOrder?.id, comment, rating: progress } }).then(() => {
        toast.success('Thank You ! and See you next time');
        setTimeout(() => {
          history.push('/');
        }, 2000);
      });
    } else {
      toast.warning('Please Re-Scan the QR code');
    }
  };

  const tab = [<BsEmojiAngry />, <BsEmojiFrown />, <BsEmojiNeutral />, <BsEmojiSmile />, <BsEmojiLaughing />];
  return (
    <div className="h-[100vh] flex flex-col px-4 gap-2 font-PopinsMedium">
      <Header title="Reviews" />
      <div className="flex flex-col justify-center items-center gap-4 h-full">
        <div className="border border-gray-100 shadow-lg rounded-2xl px-5 py-4 flex flex-col gap-6">
          <p className="text-center text-sm">Rate us</p>
          <div className="flex flex-row gap-1 justify-center text-[42px]">
            {tab[progress - 1]}
            <div className="flex text-[34px] gap-3">
              {[...Array(5)].map((el, i) =>
                i < progress ? (
                  <AiFillStar onClick={() => setProgress(i + 1)} />
                ) : (
                  <AiOutlineStar onClick={() => setProgress(i + 1)} />
                ),
              )}
            </div>
          </div>
          <div className="flex flex-col items-center gap-2  ">
            <p className="text-menupwacenter text-sm">Leave a comment</p>
            <textarea
              value={comment}
              onChange={(e) => setcomment(e.target.value)}
              style={{ resize: 'none' }}
              className="textarea-pwa"
              placeholder="Enter your comment"
            />
            <CustomBtn
              loading={loading}
              loadingColor="white"
              onClick={() => handleReview()}
              disabled={!comment}
              text="send"
              Icon={FiSend}
              className={` duration-300 !bg-[transparent] hover:border-none ${
                !comment && '!text-gray-400'
              } text-sm !gap-1 !h-auto !p-0 !m-0`}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
