import { FaMinus, FaPlus } from 'react-icons/fa';

interface Props {
  quantity: number;
  handleChange: (quantity: number, label: string) => void;
  selectedQuantity: number;
  label: string;
}
export default function Quantity({ quantity, handleChange, selectedQuantity, label }: Props) {
  return (
    <div className="flex justify-center items-center gap-4">
      <div
        onClick={() => (selectedQuantity > 1 ? handleChange(selectedQuantity - 1, label) : null)}
        className="h-[18px] w-[18px] text-[8px] rounded-[5px] flex justify-center items-center bg-grey text-white"
      >
        <FaMinus />
      </div>
      <p className="font-PopinsMedium text-[20px]">{selectedQuantity}</p>
      <div
        onClick={() => (selectedQuantity < quantity ? handleChange(selectedQuantity + 1, label) : null)}
        className="h-[15px] w-[18px] text-[8px] rounded-[5px] flex justify-center items-center bg-[#C7B79F] cursor-pointer text-white"
      >
        <FaPlus />
      </div>
    </div>
  );
}
