import QRcodeEMpty from 'assets/svg/NoQr.svg';
import Layout from 'components/layout/Layout';
import { Route as BaseRoute, RouteProps as BaseRouteProps } from 'react-router-dom';
import Footer from '../FooterPWA/Footer';
import Header from '../HeaderPWA/Header';
import classes from './route.module.scss';

export function NoIdTAble() {
  return (
    <div className="flex flex-col justify-between items-center h-[100vh]">
      <Header />
      <div className="flex flex-col items-center gap-9">
        <img width={200} alt="dfsdf" src={QRcodeEMpty} />
        <div className="font-PopinsMedium text-center"> Sorry Please Scan the Table QRcode</div>
      </div>
      <Footer />
    </div>
  );
}

export interface RouteProps extends BaseRouteProps {
  protected?: boolean;
  firstcnx?: boolean;
  header?: boolean;
  dashboardLayout?: boolean;
}

const Route = ({ protected: protectedProp, header, dashboardLayout, firstcnx, ...rest }: RouteProps) => {
  const idTable = localStorage.getItem('idTable');

  // if (protectedProp && !user) {
  //   return <Redirect to={{ pathname: '/login', search:
  // encodeUri({ from: location.pathname + location.search }) }} />;
  // }

  if (!idTable && !firstcnx) {
    return <NoIdTAble />;
  }

  return (
    <div className={`${classes.container} flex`}>
      {dashboardLayout ? (
        <Layout>
          <BaseRoute {...rest} />
        </Layout>
      ) : (
        <BaseRoute {...rest} />
      )}
    </div>
  );
};

Route.defaultProps = {
  header: true,
};

export default Route;
