import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

interface Props {
  goBack?: boolean;
  onReturnClick?: () => any;
}
export default function Back({ onReturnClick, goBack }: Props) {
  const history = useHistory();
  const clickFuction = () => {
    if (onReturnClick) {
      onReturnClick();
      return;
    }
    if (goBack) {
      history.goBack();
    }
  };
  return (
    <div
      className="flex justify-center items-center cursor-pointer transition-colors mt-2 !text-[#A3A2A0]"
      onClick={clickFuction}
    >
      <IoIosArrowBack className="text-2xl" />
      <p className="text-sm ">Back</p>
    </div>
  );
}
Back.defaultProps = {
  goBack: true,
};
