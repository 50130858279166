import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { Menu, PointSale, Restaurant } from 'requests/types';

export const useCreatPointSale = (options: MutationHookOptions<{ pointSale: PointSale }> = {}) => {
  const USER_UPDATE = gql`
    mutation createPointSale(
      $Point_type: String
      $name: String
      $legal_business_name: String
      $business_structure: String
      $tax_id_number: String
      $registred_address: String
      $iban: String
      $idOwner: ID
      $idManagers: [ID]
      $idWaiters: [ID]
      $pos_informations: PointSalePos_informationsInput
      $menu_qr_code: String
    ) {
      createPointSale(
        Point_type: $Point_type
        name: $name
        legal_business_name: $legal_business_name
        business_structure: $business_structure
        tax_id_number: $tax_id_number
        registred_address: $registred_address
        iban: $iban
        idOwner: $idOwner
        idManagers: $idManagers
        idWaiters: $idWaiters
        pos_informations: $pos_informations
        menu_qr_code: $menu_qr_code
      ) {
        id
      }
    }
  `;
  return useLocalMutation(USER_UPDATE, options);
};

// Get Menus
export const useGetPointSale = (options: QueryHookOptions<{ getPointSale: PointSale[] }, {}> = {}) => {
  const menuutation = gql`
    query getPointSale($idOwner: ID) {
      getPointSale(idOwner: $idOwner) {
        id
        Point_type
        name
        legal_business_name
        business_structure
        tax_id_number
        registred_address
        iban
        idManagers {
          id
        }
        idWaiters {
          id
        }
        pos_informations {
          pos_brand
          email
          password
          table_plan
        }
        tips
        transportation {
          valet
          taxi
          careem
        }
        appDesign {
          button_name
          button_link
        }
        menu_qr_code
      }
    }
  `;

  return useLocalQuery(menuutation, options);
};

export const useGetPointSaleByParams = (
  options: QueryHookOptions<
    { getPointByParams: { menus: Menu[]; pointOfSale: Restaurant } },
    { id?: string; idOwner?: string; idTable?: string }
  > = {},
) => {
  const sayedmutation = gql`
    query getPointByParams($id: ID, $idTable: ID, $idOwner: ID) {
      getPointByParams(id: $id, idTable: $idTable, idOwner: $idOwner) {
        menus {
          image
          description
          category
          visible
          order
        }
        pointOfSale {
          id
          Point_type
          name
          point_sale_logo
          tips
          transportation {
            valet
            taxi
            careem
          }
          appDesign {
            button_name
            button_link
          }
        }
      }
    }
  `;
  return useLocalQuery(sayedmutation, options);
};
