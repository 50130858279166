import SideBarMainBtn from 'components/ui/Buttons/SideBarMainBtn';
import UserContext from 'contexts/rootContext';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineAntDesign, AiOutlineStar } from 'react-icons/ai';
import { BsMenuUp } from 'react-icons/bs';
import { FaExpandArrowsAlt, FaPercentage } from 'react-icons/fa';
import { FiArrowLeftCircle, FiArrowRightCircle, FiSettings, FiUsers } from 'react-icons/fi';
import { HiOutlineTicket } from 'react-icons/hi';
import { MdOutlineQrCode2, MdPayment } from 'react-icons/md';
import { SiGooglemaps } from 'react-icons/si';
import classes from './sideBar.module.scss';

const MAIN_BUTTONS = [
  {
    text: 'Ticket',
    Icon: HiOutlineTicket,
    link: '/ticket',
  },
  {
    text: 'Payment',
    Icon: MdPayment,
    link: '/payment',
  },
  {
    text: 'Accounting',
    Icon: FaPercentage,
    link: '/accounting',
  },
  {
    text: 'Rating',
    Icon: AiOutlineStar,
    link: '/rating',
  },
];

const SETTINGS_BUTTONS = [
  {
    text: <span>Restaurant details</span>,
    Icon: BsMenuUp,
    link: '/restaurant-details',
  },
  {
    text: 'My Staff',
    Icon: FiUsers,
    link: '/waiters',
  },
  {
    text: 'Point of sales',
    Icon: SiGooglemaps,
    link: '/POS',
  },
  {
    text: 'Menus',
    Icon: FaExpandArrowsAlt,
    link: '/menu',
  },
  {
    text: 'App design',
    Icon: AiOutlineAntDesign,
    link: '/app-design',
  },
  {
    text: 'QR codes',
    Icon: MdOutlineQrCode2,
    link: '/qr-codes',
  },
];

export default function SideBar() {
  const [tabletSize, settabletSize] = useState(false);
  const { user } = useContext(UserContext);

  const handelREsize = ({ target }: any) => {
    const windowWidth = target?.innerWidth;
    if (windowWidth <= 767) settabletSize(true);
    else settabletSize(false);
  };
  useEffect(() => {
    window.addEventListener('resize', handelREsize);
    return () => {
      window.removeEventListener('resize', handelREsize);
    };
  }, []);
  return (
    <div
      className={`${classes.container} relative flex flex-col justify-between  duration-500 ${
        tabletSize ? 'w-[4rem]' : 'w-[15rem]'
      } border-r-[1px]  border-grey-200 h-auto `}
    >
      <div>
        {MAIN_BUTTONS.map((btnProps) => (
          <SideBarMainBtn key={btnProps.text} tabletSize={tabletSize} {...btnProps} />
        ))}
      </div>
      <div>
        <div
          className={`border-t-[1px] flex items-center  border-b-[1px] h-14 py-2 border-grey-200  ${
            tabletSize && 'justify-center'
          }`}
        >
          <FiSettings className={`  text-[20px] ${!tabletSize && 'hidden'} `} />
          <h2 className={`text-3xl pl-8 text-warmGray-400 ${tabletSize && 'hidden'} `}>Settings</h2>
        </div>
        <div>
          {SETTINGS_BUTTONS.map((btnProps) => (
            <SideBarMainBtn key={btnProps.link} tabletSize={tabletSize} {...btnProps} />
          ))}
        </div>
      </div>
      <div
        className={`border-t-[1px] flex items-center justify-center  border-b-[1px] h-14 py-2 border-grey-200  ${
          tabletSize && 'justify-center'
        }`}
      >
        <div className="flex items-center justify-center">
          <div className="rounded-full w-12 border-2">
            <img
              className=" rounded-full"
              src="https://media.istockphoto.com/vectors/human-face-avatar-icon-profile-for-social-network-european-woman-vector-id1235058000?k=20&m=1235058000&s=170667a&w=0&h=UXqabIgYrferNlLnvzS4axY5_uoRCC4g8hQ-xHH50lg="
              alt=""
            />
          </div>
          {!tabletSize && (
            <div className="flex flex-col text-md p-5 justify-center">
              <span>
                {user?.first_name} {user?.last_name}
              </span>
            </div>
          )}
        </div>
      </div>
      <div onClick={() => settabletSize(!tabletSize)} className="absolute z-10 bottom-4 right-[-14px]">
        {!tabletSize ? (
          <FiArrowLeftCircle className="text-3xl opacity-50 hover:opacity-100 cursor-pointer" />
        ) : (
          <FiArrowRightCircle className="text-3xl opacity-50 hover:opacity-100 cursor-pointer" />
        )}
      </div>
    </div>
  );
}
