// import RestaurantLogo from 'assets/restaurant-logo.png';
import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
// import { useGetPointSaleByParams } from 'requests/PointSale/request';

interface Props {
  goBack?: boolean;
  onReturnClick?: () => any;
  title?: string;
}

export default function Header({ goBack, onReturnClick, title }: Props) {
  const history = useHistory();
  // const idTable = localStorage.getItem('idTable') || undefined;
  // const { data } = useGetPointSaleByParams({
  //   variables: { idTable },
  //   skip: !idTable,
  // });
  const clickFuction = () => {
    if (onReturnClick) {
      onReturnClick();
    }
    if (goBack) {
      history.goBack();
    }
  };
  return (
    <div className="h-[120px] flex justify-center py-7">
      <div
        className="absolute top-5 flex justify-center items-center left-5 h-[45px] w-[45px] hover:bg-gray-200 rounded-xl hover:text-grey hover:shadow-lg cursor-pointer transition-colors"
        onClick={clickFuction}
      >
        <IoIosArrowBack className="text-2xl" />
      </div>
      <p className="text-xl font-semibold">{title}</p>
      {/* <img
        src={data?.getPointByParams?.pointOfSale.point_sale_logo || RestaurantLogo}
        alt="restaurant-logo"
        className="h-[64px]"
      /> */}
    </div>
  );
}

Header.defaultProps = {
  goBack: true,
  title: '',
};
