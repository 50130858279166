import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { Ticket } from './types';

export const useGetTickets = (options: QueryHookOptions<{ Orders: { data: Ticket[] } }, {}> = {}) => {
  const GET_TICKETS_QUERY = gql`
    {
      Orders {
        data {
          id
          total_amount
          total_amount_payed
          tip_amount
          status
          createdAt
          items_id {
            id
            label
            price
            image
          }
          waiter_id {
            id
            first_name
            last_name
          }
        }
      }
    }
  `;

  return useLocalQuery(GET_TICKETS_QUERY, options);
};

export const useGetItemByTable = (options: QueryHookOptions<{ getOrderByTable: Ticket }, {}> = {}) => {
  const GET_TICKETS_QUERY = gql`
    query getOrderByTable($table_id: ID, $status: String) {
      getOrderByTable(table_id: $table_id, status: $status) {
        id
        status
        tip_amount
        table_id {
          id
          status
        }
        items_id {
          item_id {
            id
          }
          quantity
          label
          price
          image
        }
      }
    }
  `;

  return useLocalQuery(GET_TICKETS_QUERY, options);
};

export const useSendReview = (
  options: MutationHookOptions<{ Ticket: Ticket }, { comment: string; idOrder: string; rating: number }> = {},
) => {
  const UPDATE_REVIEW = gql`
    mutation updateOrderReview($idOrder: String, $rating: Int, $comment: String) {
      updateOrderReview(idOrder: $idOrder, rating: $rating, comment: $comment) {
        id
        total_amount
        rating
        comment
      }
    }
  `;
  return useLocalMutation(UPDATE_REVIEW, options);
};
