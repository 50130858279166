import Route, { NoIdTAble } from 'components/ui/Route/Route';
import MainContext from 'contexts/rootContext';
import { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MenuItem } from 'requests/MenuItems/types';
import { useGetItemByTable } from 'requests/Tickets/request';
import { Ticket } from 'requests/Tickets/types';
import { PaymentIntent, Restaurant, Table, User } from 'requests/types';
import startup from 'utils/startup';
import { socket, SocketContext } from '../contexts/socket';
import CarWaitingLayout from './Accounting/CarWaiting/CarWaitingLayout';
import BillDetail from './BillDetailPWA/BillDetail';
import BillLayout from './BillPWA/BillLayout';
import CardInformationPWA from './CardInformationPWA/CardInformationPWA';
import CashLayout from './Cash/CashLayout';
import CheckoutPWA from './CheckoutPWA/CheckoutPWA';
import ChooseItemsPWA from './ChooseItemsPWA/ChooseItemsPWA';
import ChooseOrderPWA from './ChooseOrderPWA/ChooseOrderPWA';
import ChooseYourCarLayout from './ChooseYourCar/ChooseYourCarLayout';
import DoneLayout from './Done/DoneLayout';
import LandingLayout from './LandingPWA/LandingLayout';
import LoadingLayout from './Loading/LoadingLayout';
import MenuLayout from './MenuPWA/MenuLayout';
import RatingPWA from './RatingPWA/RatingPWA';
import SplitTheBillLayout from './SplitTheBill/SplitTheBillLayout';
import TipsPWA from './TipsPWA/TipsPWA';
import ValletParkingLayout from './ValletParking/ValletParkingLayout';
import FailedLayout from './Failed/Failed';

const RootContainer = () => {
  // const history = useHistory();
  const [user, setUser] = useState(null as User | null);
  const [paymentIntent, setPaymentIntent] = useState(null as PaymentIntent | null);
  const [items, setItems] = useState([] as MenuItem[] | null);
  const [restaurant, setRestaurant] = useState(null as Restaurant | null);
  const [currentTable, setCurrentTable] = useState(null as Table | null);
  const [currentOrder, setCurrentOrder] = useState(null as Ticket | null);
  const [firstOpen, setFirstOpen] = useState(true);
  const [, setStartupEnded] = useState(false);
  const [socketIsConntected, setSocketIsConnected] = useState(false);
  const { data, error, called } = useGetItemByTable({
    variables: { table_id: localStorage.getItem('idTable'), status: 'opened' },
    fetchPolicy: 'no-cache',
  });

  // setup socket
  useEffect(() => {
    socket.on('connect', () => {
      setSocketIsConnected(true);
    });

    socket.on('disconnect', () => {
      setSocketIsConnected(false);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };
  }, [socketIsConntected]);

  useEffect(() => {
    startup().then((u) => {
      setUser(u);
      setStartupEnded(true);
    });
  }, []);

  useEffect(() => {
    // setCurrentOrder
    if (!error && data?.getOrderByTable) {
      setCurrentOrder(data?.getOrderByTable);
      //    set the items
      setItems(data?.getOrderByTable?.items_id || []);
    } else if (called) {
      toast.error(error?.message);
    }
  }, [data, error]);

  return (
    <MainContext.Provider
      value={{
        currentTable,
        setCurrentTable,
        currentOrder,
        setCurrentOrder,
        user,
        setUser,
        paymentIntent,
        setPaymentIntent,
        items,
        setItems,
        restaurant,
        setRestaurant,
        firstOpen,
        setFirstOpen,
      }}
    >
      <SocketContext.Provider value={socket}>
        <ToastContainer />
        <Switch>
          {/* Tables Handling */}
          <Route path="/:idTable/landing" firstcnx exact component={LandingLayout} />
          <Route path="/menupwa" exact component={MenuLayout} />
          <Route path="/billpwa" exact component={BillLayout} />
          <Route path="/billdetail" exact component={BillDetail} />
          {/* How to Pay */}
          <Route path="/chooseorderpwa" exact component={ChooseOrderPWA} />
          {/* Payment Choices */}
          <Route path="/split" exact component={SplitTheBillLayout} />
          <Route path="/chooseitemspwa" exact component={ChooseItemsPWA} />
          {/* Tips Option  */}
          <Route path="/tipspwa" exact component={TipsPWA} />
          {/* Payment Type  */}
          <Route path="/checkoutpwa" exact component={CheckoutPWA} />
          <Route path="/cash" exact component={CashLayout} />
          {/* Payment Process */}
          <Route path="/cardinformationpwa" exact component={CardInformationPWA} />
          <Route path="/ratingpwa" exact component={RatingPWA} />
          <Route path="/done" exact component={DoneLayout} />
          {/* After Pay */}
          <Route path="/chooseyourcar" exact component={ChooseYourCarLayout} />
          {/* Valet */}
          <Route path="/valletparking" exact component={ValletParkingLayout} />
          {/* Car Waiting */}
          <Route path="/carwaiting" exact component={CarWaitingLayout} />
          <Route path="/loading" exact component={LoadingLayout} />
          <Route path="/failed" exact component={FailedLayout} />
          <Route component={NoIdTAble} />
        </Switch>
      </SocketContext.Provider>
    </MainContext.Provider>
  );
};

export default RootContainer;
