import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
// import CheckoutNetwork from 'containers/CheckoutNetwork/CheckoutNetwork';
import rootContext from 'contexts/rootContext';
import { useContext, useState } from 'react';
import { sendOneStagePaymentRequest } from 'requests/network';
import { useHistory } from 'react-router-dom';
import card from '../../assets/card.png';

type CardInformation = {
  pan: string;
  expiry: string;
  cvv: number;
  cardholderName: string;
};
export default function CardInformationPWA() {
  const [cardInformation, setCardInformation] = useState<CardInformation>({
    pan: '2303779999000275',
    expiry: '2025-12',
    cvv: 111,
    cardholderName: 'Test test',
  });
  const [isPaying, setIsPaying] = useState(false);
  const { paymentIntent, currentOrder, restaurant } = useContext(rootContext);
  const { NI } = window as any;
  const history = useHistory();
  const pay = async () => {
    setIsPaying(true);
    try {
      const response = await sendOneStagePaymentRequest({
        order: {
          action: 'PURCHASE',
          amount: {
            value: ((paymentIntent?.amount as number) + (paymentIntent?.tips as number)) * 100,
            currencyCode: 'AED',
          },
        },
        cardInformation,
      });

      const { status } = await NI.handlePaymentResponse(response, {
        mountId: '3ds_iframe',
        style: { width: '100%', height: 500 },
      });
      const paymentObject = {
        total_payed: (paymentIntent?.amount as number) * 100,
        id_table: currentOrder?.table_id.id,
        idPointSale: restaurant?.id,
        tip: (paymentIntent?.tips as number) * 100,
      };
      if (
        status === NI.paymentStates.AUTHORISED ||
        status === NI.paymentStates.CAPTURED ||
        status === NI.paymentStates.PURCHASED ||
        status === NI.paymentStates.THREE_DS_SUCCESS
      ) {
        /// do something to show status on UI

        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(paymentObject),
        });
        history.push('/done');
        setIsPaying(false);
      } else if (status === NI.paymentStates.FAILED || status === NI.paymentStates.THREE_DS_FAILURE) {
        // do something for failed case
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...paymentObject, status: 'FAILED' }),
        });
      }
    } catch (err) {
      // console.error(err);
    }
  };
  const handlePayment = () => {};
  return (
    <div className="h-[100vh] min-h-[550px] flex flex-col px-4 font-PopinsMedium">
      <Header title="Card information" />
      <div className="flex flex-col h-full justify-center">
        <div className="flex flex-col h-full max-h-[600px] gap-2 justify-between">
          <div className="flex items-center flex-col gap-[5px]">
            {/* <CheckoutNetwork /> */}
            <p>Enter your card information</p>
            <div id="3ds_iframe" className="w-100" />
            <div id="network" />

            <img src={card} alt="" />
            <p className="text-[#C7B79F] underline text-[9px] font-light hover:text-gray-700 cursor-pointer">
              Scan youar card
            </p>
          </div>
          <div className="flex justify-center">
            <hr className="border-gray-100 w-[80%]" />
          </div>
          <form className="px-4 flex-col flex gap-4 ">
            <input
              type="text"
              value={cardInformation.pan}
              onChange={(e: any) => {
                setCardInformation({ ...cardInformation, pan: e.target.value });
              }}
              className="input-pwa"
              placeholder="Card Number"
            />
            <input
              onChange={(e: any) => {
                setCardInformation({ ...cardInformation, expiry: e.target.value });
              }}
              type="text"
              value={cardInformation.expiry}
              className="input-pwa"
              placeholder="Expiration Date"
            />
            <input
              onChange={(e: any) => {
                setCardInformation({ ...cardInformation, cardholderName: e.target.value });
              }}
              type="text"
              value={cardInformation.cardholderName}
              className="input-pwa"
              placeholder="Cardholder Name"
            />
            <input
              onChange={(e: any) => {
                setCardInformation({ ...cardInformation, cvv: e.target.value });
              }}
              type="text"
              value={cardInformation.cvv}
              className="input-pwa"
              placeholder="CVC/CVV2"
            />
          </form>
          <div className="flex items-center flex-col">
            <CustomBtn text="Pay" className="confirm-btn" onClick={pay} disabled={isPaying} />
          </div>
          <div className="flex items-center flex-col">
            <CustomBtn text="Confirm" className="confirm-btn" onClick={handlePayment} />
            <Back />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
