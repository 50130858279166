import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';

export default function FailedLayout() {
  const history = useHistory();
  return (
    <div className="h-[100vh] min-h-[500px] w-full flex flex-col font-PopinsMedium bg-black">
      <div className="flex font-bold justify-center items-center text-white flex-col gap-5 h-full">
        <img src="failed.png" alt="bill" className="w-[100px]" />
        <p className="text-center text-[31px] text-bold">
          YOUR
          <br />
          PAYEMENT
          <br />
          Has Failed !
        </p>
      </div>
      <div className="bg-white h-[212px] rounded-b-none rounded-l-none rounded-[49px/20px] text-black animate-slide">
        <div className="flex flex-col justify-evenly h-full px-10">
          <div className="flex justify-between items-center">
            <p className="text-xl italic">Do you want to try again ?</p>
            <IoIosArrowForward className="text-3xl" />
          </div>
          <div className="flex flex-row gap-4 w-full justify-center ">
            <Link className="w-full" to={{ pathname: `${localStorage.getItem('idTable')}/landing/` }}>
              <CustomBtn text="Try again" className="confirm-btn w-full flex justify-center !px-0 !m-0" />
            </Link>
          </div>
          <Back onReturnClick={() => history.push(`${localStorage.getItem('idTable')}/landing/`)} />
        </div>
      </div>
    </div>
  );
}
