import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import { useContext, useEffect, useState } from 'react';
import rootContext from 'contexts/rootContext';

import { IoReturnDownForwardSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Back from 'components/ui/Back/Back';
import Quantity from './Quantity';

export default function ChooseItemsPWA() {
  const { items, setPaymentIntent } = useContext(rootContext);
  const [selectedItems, setSelectedItems] = useState([] as any);
  const [total, setTotal] = useState(0);
  const [first, setFirst] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (!first) {
      setSelectedItems(
        items?.map((item) => {
          return { ...item, selected: false, selectedQuantity: 1 };
        }),
      );
      setFirst(true);
    }
  }, []);

  useEffect(() => {
    setTotal(
      Number(
        selectedItems
          ?.filter((item: any) => item.selected === true)
          .reduce((acc: number, item: any) => acc + Number(item.price * item.selectedQuantity), 0),
      ),
    );
    setError('');
  }, [selectedItems]);

  const handleSelectedItems = (e: any) => {
    const newItems = [...selectedItems];
    setSelectedItems(
      newItems.map((newItem: any) => {
        if (newItem.label === e.target.value) {
          return { ...newItem, selected: !newItem.selected };
        }
        return { ...newItem };
      }),
    );
  };
  const handleQuantityChange = (quantity: number, label: string) => {
    const newItems = [...selectedItems];
    setSelectedItems(
      newItems.map((newItem: any) => {
        if (newItem.label === label) {
          return { ...newItem, selectedQuantity: quantity };
        }
        return { ...newItem };
      }),
    );
  };
  const handleConfirmAmount = (e: any) => {
    if (!total) {
      setError('Please select at least one item');
      e.preventDefault();
      return;
    }
    setError('');
    setPaymentIntent({ amount: total });
  };
  return (
    <div className="h-[100vh] flex flex-col px-5 font-PopinsMedium">
      <Header title="Choose your items" />
      <p className="text-center  text-[#38342F] font-semibold">Your Bill</p>
      <div className="h-[calc(100%-175px)] flex flex-col">
        <div className="h-full p-5 pt-10 flex flex-col gap-2">
          <div className="overflow-auto flex flex-col gap-2">
            {selectedItems?.map((item: any, index: number) => (
              <div
                key={`${item.currency} -${item.price}`}
                className={`flex flex-col ${index < 9 ? ' border-gray-200 pb-2' : ''}`}
              >
                <div className="flex flex-row items-center">
                  <input
                    type="checkbox"
                    className="mr-2 mt-1 accent-color"
                    checked={item.selected === true}
                    value={item.label}
                    onChange={(e) => {
                      handleSelectedItems(e);
                    }}
                  />

                  <p className="font-PopinsMedium">
                    {' '}
                    {item.label} x {item.quantity}{' '}
                  </p>
                  <p className="ml-auto font-PopinsMedium">{item.price * item.quantity} AED</p>
                </div>
                <div className="flex felx-row gap-1 items-center text-sm text-gray-400">
                  <p className="text-xs">
                    <div className="flex felx-row justify-between px-1 items-center text-sm text-gray-400">
                      <div className="flex items-center gap-1">
                        <IoReturnDownForwardSharp />
                        <p className="text-[9px]">{item.label}</p>
                      </div>
                      <p className="text-[9px]"> Price : {item?.price} AED</p>
                    </div>
                    {item.selected && item.quantity > 1 ? (
                      <div>
                        <Quantity {...item} handleChange={handleQuantityChange} />
                      </div>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row border-t-[1px] py-4 mt-auto border-[#A3A2A0]  pb-2 items-center">
            <p className="text-lg font-semibold text-[#38342F]">Total</p>
            <p className="ml-auto text-lg  font-semibold text-[#38342F]">{total} AED</p>
          </div>

          <div className="flex items-center flex-col">
            <span className="text-red-400">{error}</span>
            <Link to={{ pathname: '/tipspwa' }} onClick={(e) => handleConfirmAmount(e)}>
              <CustomBtn text="Confirm" className="confirm-btn !px-14" />
            </Link>
            <Back />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
