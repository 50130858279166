import React from 'react';
import './style.css';

interface Props {
  size?: 'sm' | 'md' | 'lg';
  color?: string;
}

export default function Loading({ size = 'lg', color = 'black' }: Props) {
  const sizes: { [ker: string]: string } = {
    sm: '0.3',
    md: '0.5',
    lg: '1',
  };
  return (
    <div style={{ transform: `scale(${sizes[size]})` }} className="lds-roller after:bg-red-300 ">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <style>
        {`
    .lds-roller div:after {
      background : ${color} !important ;
    `}
      </style>
    </div>
  );
}
