import React from 'react';
import logo from '../../assets/logoLoading.png';

export default function () {
  return (
    <div className="h-screen bg-gray-900 flex justify-center items-center">
      <img className="h-[141px] w-[141px]" src={logo} alt="" />
    </div>
  );
}
