import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import rootContext from 'contexts/rootContext';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function TipsPWA() {
  const { paymentIntent, setPaymentIntent } = useContext(rootContext);
  const [tips, setTips] = useState(0 as number);
  const [defaultTipsOptins] = useState([5, 10, 15, 20]);
  useEffect(() => {
    setPaymentIntent({ ...paymentIntent, tips } as any);
  }, [tips]);
  return (
    <div className="h-[100vh] min-h-[500px] flex flex-col px-6 font-PopinsMedium">
      <Header title="Tips" />
      <div className="flex flex-col h-full justify-center">
        <div className="flex flex-col h-full max-h-[650px] justify-between">
          <div className="flex flex-col items-center gap-4">
            <p>Would you like to leave a tip ?</p>
          </div>
          <div className="flex justify-around">
            {defaultTipsOptins.map((tip, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <div
                  onClick={() => {
                    setTips((tip * (paymentIntent?.amount as number)) / 100);
                    const tipAmount = (tip * (paymentIntent?.amount as number)) / 100;
                    localStorage.setItem('tip', tipAmount as any);
                  }}
                  className="border rounded-xl hover:text-white hover:bg-grey hover:shadow-none shadow-md flex justify-center items-center h-[70px] w-[70px]"
                >
                  <div className="flex flex-col items-center justify-center">
                    <span>{(tip * (paymentIntent?.amount as number)) / 100} AED</span>
                    <span className="font-bold font ">{tip} % </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center flex-col gap-4">
            <h1 className="text-xs font-medium">Choose another amount</h1>
            <input
              type="number"
              className="input-pwa !w-[250px] !text-[#B3B3B3]"
              placeholder="0 AED"
              value={`${tips === 0 ? '' : tips}`}
              onChange={(e) => {
                setTips(Number(e.target.value));
                localStorage.setItem('tip', e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col px-2 gap-2">
            <div className="flex justify-between">
              <p>Your bill</p>
              <p>{Number(paymentIntent?.amount)} AED</p>
            </div>
            <div className="border-b-[1px] flex justify-between pb-2 text-gray-400 text-sm">
              <p>Tip</p>
              <p>{tips} AED</p>
            </div>
            <div className="flex justify-between text-lg font-semibold text-[#38342F] py-2">
              <p>Total</p>
              <p>{Number(paymentIntent?.amount) + tips} AED</p>
            </div>
            <div className="flex items-center flex-col gap-2 mt-4">
              <div className="flex items-center flex-col">
                <Link to={{ pathname: '/checkoutpwa' }}>
                  <CustomBtn text="Confirm" className="confirm-btn !px-14" />
                </Link>
                <Back />
              </div>
              <Link
                to={{ pathname: '/checkoutpwa' }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <p className="underline text-sm text-grey">I don't want to leave tip</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
