import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import rootContext from 'contexts/rootContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import { CardForm } from '../../components/ui/Checkout/CardForm';

export default function SplitTheBillLayout() {
  const [personsNb, setPersonsNb] = useState(2);
  const [partsNb, setPartsNb] = useState(1);
  const [amount, setAmount] = useState(0);
  const [totalToBePaid, setTotalToBePaid] = useState(0);
  const { setPaymentIntent, items } = useContext(rootContext);
  const handleMinus = () => {
    if (personsNb > 2) {
      if (personsNb === partsNb) {
        setPartsNb(partsNb - 1);
      }
      setPersonsNb(personsNb - 1);
    }
  };
  const handleSplitBill = () => {
    setAmount((totalToBePaid / personsNb) * partsNb);
  };
  const calculateTotalToBePaid = () => {
    setTotalToBePaid(
      Number(Number(items?.reduce((acc: number, item: any) => acc + Number(item.price * item.quantity), 0))),
    );
  };
  useEffect(() => {
    calculateTotalToBePaid();
    setAmount(Number((totalToBePaid / personsNb).toFixed(2)));
    setPaymentIntent({
      currency: 'usd',
      amount: amount * partsNb,
      payment_method_types: ['card'],
    });
  }, [amount, partsNb]);
  useEffect(() => {
    const num = Number(Number(items?.reduce((acc: number, item) => acc + Number(item.price), 0)) / personsNb).toFixed(
      2,
    );
    setAmount(Number(num));
  }, [personsNb]);
  return (
    <div className="h-[100vh] flex flex-col px-4">
      <Header title="Split the bill" />
      <div className="flex flex-col justify-evenly  h-full items-center font-PopinsMedium max-h-[600px]">
        <p className="font-bold ">How many people ?</p>
        <div className="h-[227px] w-[284px] border-[1px] rounded-2xl border-gray-100 shadow-lg cursor-pointer flex items-center flex-col p-[21px]">
          <div className="flex justify-center items-center gap-8 h-[136px] border-b-[1px] border-gray-100">
            <div
              onClick={handleMinus}
              className="h-[32px] w-[32px] rounded-xl flex justify-center items-center bg-grey text-white"
            >
              <FaMinus />
            </div>
            <p className="font-PopinsMedium text-[112px]">{personsNb}</p>
            <div
              onClick={() => setPersonsNb(personsNb + 1)}
              className="h-[32px] w-[32px] rounded-xl flex justify-center items-center bg-grey cursor-pointer text-white"
            >
              <FaPlus />
            </div>
          </div>
          <p className="mt-auto">{amount} AED each one</p>
        </div>
        <p className="text-xs text-gray-400">How many part you wanna pay</p>
        <div className="flex justify-center items-center gap-4">
          <div
            onClick={() => (partsNb > 1 ? setPartsNb(partsNb - 1) : null)}
            className="h-[18px] w-[18px] text-[8px] rounded-[5px] flex justify-center items-center bg-grey text-white"
          >
            <FaMinus />
          </div>
          <p className="font-PopinsMedium text-[38px]">{partsNb}</p>
          <div
            onClick={() => (partsNb < personsNb ? setPartsNb(partsNb + 1) : null)}
            className="h-[18px] w-[18px] text-[8px] rounded-[5px] flex justify-center items-center bg-grey cursor-pointer text-white"
          >
            <FaPlus />
          </div>
        </div>

        <div className="flex items-center flex-col">
          <Link
            to={{ pathname: '/tipspwa' }}
            onClick={() => {
              handleSplitBill();
            }}
          >
            <CustomBtn text="Confirm" loadingColor="white" className="confirm-btn" type="button" />
          </Link>
          <Back />
        </div>
      </div>
      {/* <CardForm /> */}
      <Footer />
    </div>
  );
}
