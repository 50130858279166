import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import { useSwipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';
import { useContext, useRef, useState } from 'react';
import Back from 'components/ui/Back/Back';
import rootContext from 'contexts/rootContext';
// import CheckoutFoloosi from 'containers/CheckoutFoloosi/CheckoutFoloosi';
// import GooglePayBTN from 'components/ui/GooglePayButton/GooglePayButton';
import GooglePayBTN from 'components/ui/GooglePayButton/GooglePayButton';
// import apple from '../../assets/applePay.png';
import { createOrder } from 'requests/network';
import visa from '../../assets/visa.png';
// import alipay from '../../assets/svg/alipay.svg';

export default function CheckoutPWA() {
  const [selectedMethod, setSelectedMethod] = useState('');
  if (selectedMethod) {
    //
  }
  /* const paymentMethods = [
    { title: 'apple', image: apple },

    { title: 'instant payment' },
    { title: 'card', image: visa },

    { title: 'alipay', image: alipay },
    { title: 'google', image: '' },
    { title: 'cash', image: '' },
  ]; */
  const history = useHistory();
  const paymentMethods = [
    { title: 'card', image: visa },
    { title: 'cash', image: '' },
  ];
  const [selected, setSelected] = useState(-1);
  const [swiped, setSwiped] = useState(0);
  const handlers = useSwipeable({
    onSwipedRight: () => {
      setSwiped((e) => (e < 187 ? e + 375 : e));
    },
    onSwipedLeft: () => setSwiped((e) => (e > -188 ? e - 375 : e)),
  });
  const { paymentIntent, currentOrder } = useContext(rootContext);
  const [loadingLink, setLoadingLink] = useState(false);

  const emailRef = useRef() as any;
  const [emailError, setEmailError] = useState(false);
  return (
    <div className="h-[100vh] min-h-[500px] flex flex-col  justify-between font-PopinsMedium ">
      <Header title="Checkout" />

      <div className="h-full flex flex-col justify-center">
        <div className="h-full max-h-[600px] flex flex-col justify-between gap-3 overflow-x-hidden">
          <div className="flex items-center flex-col gap-4">
            <p className="text-center font-semibold text-[#38342F]">Select your payment method</p>

            <div
              style={{ marginLeft: `${swiped}px` }}
              className="flex flex-row px-4  gap-4 relative transition-all "
              {...handlers}
            >
              {paymentMethods.map((el, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}checkout`}
                  onClick={() => {
                    setSelected(i);
                    setSelectedMethod(el?.title);
                  }}
                  className={`${
                    selected === i ? 'border border-grey' : ''
                  } shadow-md border-[1px] border-gray-100 rounded-xl h-[66px] !w-[94px] flex justify-center items-center ${
                    el.title === 'google' ? 'p-0' : 'p-2'
                  } `}
                >
                  {el.title === 'cash' ? (
                    <div
                      className="text-sm flex justify-center items-center flex-row gap-1"
                      onClick={() => history.push('/cash')}
                    >
                      <p className="!text-lg font-semibold"> Cash </p>
                    </div>
                  ) : (
                    <img src={el.image} alt="" />
                  )}
                  {el.title === 'google' ? <GooglePayBTN amount={paymentIntent?.amount} /> : ''}
                  {el.title === 'instant payment' && (
                    <p className="text-sm text-center font-semibold ">instant payment</p>
                  )}
                </div>
              ))}
            </div>
            <p className="text-[#B3B3B3] font-light text-xs">swipe left or right</p>
          </div>
          <div className="flex justify-center">
            <div className="h-[176px] w-[284px] pt-6 px-4 flex items-center flex-col gap-4">
              <p className="text-center text-sm font-medium text-[#1D1D1D] ">
                Add your email to recieve your <br />
                receipt
              </p>
              <input type="text" className="input-pwa !text-sm" placeholder="Email adress" ref={emailRef} />
              {emailError && <span className="text-red-700 text-[14px]">please put your email address</span>}
            </div>
          </div>
          <div className="flex text-[#38342F] justify-between mx-8 font-semibold px-4 border-t-[0.5px] py-4 mt-auto border-[#ECECEC]">
            <p>Total</p>
            <p>{Number(paymentIntent?.amount) + Number(paymentIntent?.tips)} AED</p>
          </div>
          <div className="flex items-center flex-col">
            <CustomBtn
              text={loadingLink ? 'Confirm ...' : 'Confirm'}
              className="confirm-btn px-14"
              disabled={loadingLink}
              onClick={async () => {
                if (emailRef.current.value === '') {
                  setEmailError(true);
                  return;
                }
                setEmailError(false);

                setLoadingLink(true);

                const response = await createOrder({
                  action: 'PURCHASE',
                  amount: {
                    value: ((paymentIntent?.amount as number) + (paymentIntent?.tips as number)) * 100,
                    currencyCode: 'AED',
                  },
                  emailAddress: 'tkdsayed@gmail.com',

                  tableId: currentOrder?.table_id.id,
                });

                setLoadingLink(false);

                window.location.assign(response?._links?.payment.href);
              }}
            />

            <Back />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
