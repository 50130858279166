import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import rootContext from 'contexts/rootContext';
import React, { useContext } from 'react';
import { IoReturnDownForwardSharp, IoTicketOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export default function BillDetail() {
  const { items } = useContext(rootContext);

  return (
    <div className="h-[100vh] flex flex-col px-5 font-PopinsMedium">
      <Header title="Your bill" />
      <div className="h-[calc(100%-175px)] flex flex-col ">
        <div className="h-full p-5 flex flex-col gap-2">
          <div className="overflow-auto flex flex-col gap-2">
            {items?.map((item, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={`flex flex-col ${index < 9 ? 'pb-2' : ''}`}>
                <div className="flex flex-row text-[15px]">
                  <p>
                    <span className=" font-medium ">{item.label}</span>{' '}
                    <span>x {parseInt(item.quantity || '1', 10)}</span>
                  </p>
                  <p className="ml-auto text-[#38342F]">
                    {parseInt(item.price, 10) * parseInt(item.quantity || '1', 10)} AED
                  </p>
                </div>
                <div className="flex felx-row justify-between px-1 items-center text-sm text-gray-400">
                  <div className="flex items-center gap-1">
                    <IoReturnDownForwardSharp />
                    <p className="text-[9px]">{item.label}</p>
                  </div>
                  <p className="text-[9px]"> Price : {item?.price} AED</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row items-center  py-4  text-[11px] mt-auto text-[#64BC26]">
            <div className="flex felx-row gap-1 items-center ">
              <IoTicketOutline />
              <p>Remise</p>
            </div>
            <p className="ml-auto ">0 AED</p>
          </div>
          <div className="flex flex-row  pb-2 items-center text-[17px]">
            <p className="text-lg font-bold ">Total</p>
            <p className="ml-auto text-lg  font-bold ">
              {items?.reduce((acc, item) => Number(acc) + Number(item?.price) * Number(item?.quantity), 0)} AED
            </p>
          </div>
          <div className="flex items-center flex-col">
            <Link to={{ pathname: '/chooseorderpwa' }}>
              <CustomBtn text="Pay the bill" className=" text-[14px] w-[200px] confirm-btn " />
            </Link>
            <Back />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
