import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import React, { useContext } from 'react';
import { SocketContext } from 'contexts/socket';
import UserContext from 'contexts/rootContext';

export default function CashLayout() {
  const socket = useContext(SocketContext);
  const { restaurant } = useContext(UserContext);

  const payCash = () => {
    socket.emit('pay_cash', {
      data: {
        restaurantId: restaurant,
      },
    });
  };

  return (
    <div className="h-[100vh] flex flex-col px-7 relative overflow-x-hidden ">
      <Header title="Pay in cash" />
      <div className="h-full flex flex-col px-9 justify-center">
        <div className=" h-full max-h-[400px] flex flex-col justify-between items-center gap-2">
          <div className="flex justify-center">
            <div className="bg-black color-white rounded-full h-[170px] w-[170px] text-white text-[100px] flex justify-center items-center">
              $
            </div>
          </div>
          <p className="text-center font-PopinsMedium">
            Waiter is coming for you,
            <br /> <span className="font-bold">Just wait for him</span>
          </p>
          <div className="flex items-center flex-col">
            <CustomBtn
              text="Done !"
              onClick={() => {
                payCash();
              }}
              loadingColor="white"
              className="confirm-btn"
              type="button"
            />

            <Back />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
