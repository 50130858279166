import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import rootContext from 'contexts/rootContext';
import React, { useContext } from 'react';
import { GiKnifeFork } from 'react-icons/gi';
import { Link } from 'react-router-dom';

export default function BillLayout() {
  const { items } = useContext(rootContext);
  const total = items?.reduce((acc: number, item) => acc + Number(item.price) * Number(item.quantity), 0);

  return (
    <div className="h-[100vh] flex flex-col px-9 relative overflow-x-hidden font-PopinsMedium gap-10  ">
      <Header />
      <Link to={{ pathname: '/menupwa' }} className=" flex flex-row gap-2 items-center">
        <GiKnifeFork />
        <p className="underline text-xs">See the menu</p>
      </Link>
      <div className="h-[55%] rounded-tl-[30px] rounded-tr-[30px] absolute left-0 bottom-0 !bg-white w-[100vw] flex flex-col px-7 pt-7 gap-12">
        <div className="flex justify-center w-full">
          <div className="h-1 rounded-full w-[77px] bg-grey" />
        </div>
        <div className="flex flex-col justify-between h-full max-h-[230px]">
          <p className="text-center text-xl font-semibold">Your bill</p>
          <div className="flex flex-row font-bold border-b-[1px] border-gray-100 pb-2">
            <p>Total</p>
            <p className="ml-auto">
              {total} {items?.length && 'AED'}
            </p>
          </div>
          <div className="flex items-center flex-col">
            <Link to={{ pathname: '/billdetail' }}>
              <CustomBtn text="See and Pay" loadingColor="white" className="confirm-btn" type="button" />
            </Link>
            <Back />
          </div>
        </div>
        <Footer />
      </div>
      <div className=" bg-gray-100 w-[100vw] h-[100vh] absolute top-0 left-0 z-[-1]" />
    </div>
  );
}
