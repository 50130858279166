import { NoIdTAble } from 'components/ui/Route/Route';
import rootContext from 'contexts/rootContext';
import React, { useContext, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetPointSaleByParams } from 'requests/PointSale/request';
import { ArrowUpIcon } from '@heroicons/react/solid';
import { BsInstagram } from 'react-icons/bs';
import gradientLogo from '../../assets/logoLoading.png';
import logo from '../../assets/logo3.png';
import restaurantLogo from '../../assets/restaurant-logo.png';
import { getOrderStatus } from '../../requests/network';
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LandingLayout() {
  const { idTable } = useParams() as any;
  const history = useHistory();
  const query = useQuery();

  const { setRestaurant, firstOpen, setFirstOpen } = useContext(rootContext);
  if (!idTable) {
    return <NoIdTAble />;
  }

  const { data, loading } = useGetPointSaleByParams({
    variables: { idTable },
    skip: !idTable,
  });

  useEffect(() => {
    if (idTable) {
      localStorage.setItem('idTable', idTable);
      localStorage.setItem('idRestaurant', data?.getPointByParams.pointOfSale.id || '');
      setRestaurant(data?.getPointByParams.pointOfSale.id || '');
    }
    setTimeout(() => setFirstOpen(false), 3000);
  }, [idTable, loading]);

  useEffect(() => {
    if (query.get('ref')) {
      getOrderStatus(query.get('ref') as string).then((res) => {
        if (res.orderStatus === 'FAILED') {
          history.push(`/failed`);
        } else {
          history.push('/done');
        }
      });
      // two scenarios the payment successful
      // the payment failed  if the payment failed we just need to add
    }
  }, []);

  return (
    <div className="h-[100vh] flex flex-col px-5 py-4 relative overflow-x-hidden gap-16 ">
      {firstOpen ? (
        <div className="w-[100vw] h-[100vh] grid place-items-center z-20 absolute bg-[#1D1D1D] top-0 left-0 animate-slideDown">
          <img src={gradientLogo} className="w-[140px] animate-logoLoading" alt="" />
        </div>
      ) : null}

      <div className="h-[200px] flex justify-center items-center py-5  bg-gray-100 rounded-t-[17px]">
        <img
          src={data?.getPointByParams?.pointOfSale?.point_sale_logo || restaurantLogo}
          alt="restaurant-logo"
          className="h-[150px]"
        />
      </div>
      <div className=" !bg-white  flex flex-col  gap-12">
        <div className="flex flex-col justify-center h-full gap-16">
          <div className="flex flex-col justify-between h-full max-h-[300px] ">
            <div className="flex flex-col gap-6 justify-center items-center">
              <Link
                to={{ pathname: '/menupwa' }}
                className="h-[46px] w-[225px] bg-[#1D1D1D] flex flex-col gap-2 items-center justify-center rounded-2xl text-white cursor-pointer ont-PopinsMedium hover:!bg-grey hover:!text-gray-800"
              >
                <div className="flex flex-col gap-2 items-center justify-center font-montserrat hover:border-none hover:!bg-grey hover:!text-white">
                  <p>See the Menu</p>
                </div>
              </Link>

              <Link
                to={{ pathname: '/billpwa' }}
                className="h-[46px] w-[225px] bg-[#1D1D1D] flex flex-col gap-2 items-center justify-center rounded-2xl text-white cursor-pointer ont-PopinsMedium hover:!bg-grey hover:!text-gray-800"
              >
                <div className="flex flex-col gap-2 items-center justify-center hover:border-none hover:!bg-grey hover:!text-white">
                  <p>Pay the bill</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex flex-col justify-between h-full max-h-[300px] px-10">
            <div className="flex flex-col gap-6 justify-center items-between">
              <a
                target="_blank"
                href="https://quickbillapp.com/"
                className="h-[46px] flex px-[30px] py-[20px]  flex-col gap-2 items-center justify-center rounded-2xl cursor-pointer ont-PopinsMedium hover:!bg-grey hover:!text-gray-800"
                rel="noreferrer"
              >
                <div className="w-full font-medium flex gap-2 items-center justify-between font-montserrat hover:border-none hover:!bg-grey hover:!text-white">
                  <p>Visit our website</p>
                  <ArrowUpIcon width={20} />
                </div>
              </a>

              <a
                target="_blank"
                href="https://www.instagram.com/quickbillapp/"
                className="h-[46px]  px-[30px] py-[20px] flex flex-col gap-2 items-center justify-center rounded-2xl  cursor-pointer ont-PopinsMedium hover:!bg-grey hover:!text-gray-800"
                rel="noreferrer"
              >
                <div className=" w-full font-medium  flex  gap-2 items-center justify-between hover:border-none hover:!bg-grey hover:!text-white">
                  <p>visit our instagram</p>
                  <BsInstagram className="text-[20px]" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-col items-center fixed bottom-4 left-0 w-full">
        <p className="text-[#A3A2A0] text-[7px] pl-[20px]">Powered by</p>
        <img src={logo} alt="logo" className="w-[76px]" />
      </div>
    </div>
  );
}
