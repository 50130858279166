/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import Back from 'components/ui/Back/Back';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import React, { useContext, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link /* useHistory */, useHistory } from 'react-router-dom';
import { SocketContext } from 'contexts/socket';

export default function ValletParkingLayout() {
  // const history = useHistory();
  const socket = useContext(SocketContext);
  const [tickets, setTickets] = useState([] as any);
  const [ticket, setTicket] = useState('');
  const [time, setTime] = useState(0);
  const [error, setError] = useState('');
  const [rangeOneSelected, setrageOneSelected] = useState(0);
  const history = useHistory();
  const orderCar = () => {
    socket.emit('order_car', {
      data: {
        restaurantId: localStorage.getItem('idRestaurant'),
        tickets,
        timeToCar: time,
      },
    });
  };

  const handleAddMoreTickets = () => {
    // eslint-disable-next-line eqeqeq
    if (ticket.length == 0) {
      setError('Type your ticket number');
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (ticket.length != 5) {
      setError('Ticket number must be at least 5 characters');
      return;
    }
    setError('');
    setTickets([...tickets, ticket]);
    setTicket('');
  };
  const deleteTicket = (t: string) => {
    setTickets(tickets.filter((tic: string) => tic !== t));
  };
  return (
    <div className="h-[100vh] min-h-[550px] w-full flex flex-col font-PopinsMedium">
      {/* Header */}
      <Header title="Vallet Pakring" />
      <div className="flex flex-col h-full justify-evenly px-7">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row justify-between items-center">
            <p className="text-sm">Enter your ticket number</p>
            <p className="text-grey text-xs">Scan your Ticket</p>
          </div>
          <input
            className={`input-pwa ${error.length > 0 ? 'border-red-500' : ''}`}
            placeholder="Your ticket number"
            onChange={(e) => {
              setTicket(e.target.value);
            }}
            value={ticket}
          />
          {error.length > 0 && <p className="text-red-500 text-xs">{error}</p>}
          <div className="flex flex-row justify-center items-center text-gray-400 gap-1 cursor-pointer">
            <FaPlus />
            <p className="text-xs underline" onClick={handleAddMoreTickets}>
              Add a ticket
            </p>
          </div>
          <div className="flex flex-col">
            {tickets.map((t: any) => (
              <div key={t} className="flex flex-row justify-between items-center">
                <p>
                  <img
                    alt={`Barcoded value ${t}`}
                    src={`http://bwipjs-api.metafloor.com/?bcid=code128&text=${t}&includetext`}
                    width="100"
                  />
                </p>
                <button
                  className="text-red-500  border-[1px solid red]"
                  onClick={() => {
                    deleteTicket(t);
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-sm">Choose how much time to to take your car</p>
          <div className="flex flex-row items-center gap-3 cursor-pointer">
            <input
              type="checkbox"
              className="accent-color"
              id="7-10"
              checked={rangeOneSelected === 1}
              onClick={() => {
                setrageOneSelected(1);
                setTime(10);
              }}
            />
            <label htmlFor="7-10" className="text-xs">
              7 mins to 10 mins
            </label>
          </div>
          <div className="flex flex-row items-center gap-3 cursor-pointer">
            <input
              type="checkbox"
              className=" accent-color"
              id="10-15"
              checked={rangeOneSelected === 2}
              onClick={() => {
                setrageOneSelected(2);
                setTime(15);
              }}
            />
            <label htmlFor="10-15" className="text-xs">
              10 mins to 15 mins
            </label>
          </div>
        </div>

        <div className="flex items-center flex-col">
          <Link
            to={{ pathname: '/carwaiting' }}
            onClick={(e) => {
              e.preventDefault();
              orderCar();
              setTimeout(() => {
                history.push('/carwaiting');
              });
            }}
          >
            <CustomBtn text="Confirm" loadingColor="white" className="confirm-btn" type="button" />
          </Link>
          <Back />
        </div>
      </div>
      <Footer />
    </div>
  );
}
