import careem from 'assets/careem.png';
import uber from 'assets/uber.png';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import { Link } from 'react-router-dom';

export default function ChooseYourCarLayout() {
  return (
    <div className="h-[100vh] flex flex-col px-4 gap-2 font-PopinsMedium">
      <Header title="Choose your car" />
      <div className="h-full justify-center flex flex-col">
        <div className="h-full justify-between flex flex-col max-h-[600px]">
          <div className="flex justify-evenly h-full flex-col">
            <div className="flex items-center justify-center gap-6">
              <div className="border-[1px] border-gray-100 shadow-md rounded-xl w-[100px] h-[100px] flex justify-center items-center">
                <span className="font-bold text-[50px]">P</span>
              </div>
              <Link to={{ pathname: '/valletparking' }}>
                <CustomBtn
                  text="Vallet Parking"
                  className="!bg-white hover:border-none !min-w-[190px]  flex !justify-center border border-grey rounded-2xl py-[22px] hover:!bg-grey hover:!text-white !m-0"
                />
              </Link>
            </div>
            <div className="flex items-center justify-center gap-6">
              <div className="border-[1px] border-gray-100 shadow-md rounded-xl w-[100px] h-[100px] flex justify-center items-center">
                <img src={careem} alt="" />
              </div>
              <Link to={{ pathname: 'https://www.careem.com/' }} target="_blank" rel="noopener noreferrer">
                <CustomBtn
                  text="Take Carrem"
                  className="!bg-white hover:border-none !min-w-[190px] flex !justify-center border border-grey rounded-2xl py-[22px] hover:!bg-grey hover:!text-white !m-0"
                />
              </Link>
            </div>

            <div className="flex items-center justify-center gap-6">
              <div className="border-[1px] border-gray-100 shadow-md rounded-xl w-[100px] h-[100px] flex justify-center items-center">
                <img src={uber} alt="" />
              </div>
              <Link to={{ pathname: 'https://www.uber.com/' }} target="_blank" rel="noopener noreferrer">
                <CustomBtn
                  text="Take Uber"
                  className="!bg-white hover:border-none min-w-[190px]  max-w-[190px] flex !justify-center border border-grey rounded-2xl py-[22px] hover:!bg-grey hover:!text-white !m-0 !px-0"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
