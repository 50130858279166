import NavButton from 'components/ui/Buttons/NavButton';
import UserContext from 'contexts/rootContext';
import localforage from 'localforage';
import { useContext, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import { MdExitToApp } from 'react-icons/md';
import { useLogout } from 'requests/auth';
import RestaurantLogo from '../../../assets/restaurant-logo.png';
import logo from '../../../assets/logoApp.png';

const NAVBAR_LINKS = [
  { path: '/overview', text: 'Overview' },
  { path: '/myrestaurant', text: 'My Restaurant' },
  { path: '/team', text: 'My Team' },
  { path: '/contactus', text: 'Contact Us' },
];

const Navbar = () => {
  const [logoutCall, { data: logoutData }] = useLogout();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    if (logoutData) {
      setUser(null);
      localforage.clear();
    }
  }, [logoutData]);
  return (
    <div className="flex items-center  border-b-[1px] border-grey-200  w-full justift-end h-16">
      <div className=" flex justify-center w-[15rem] text-center border-r-[0px] border-grey-200  text-3xlfont-bold">
        <div className="flex justify-center items-center gap-2 ">
          <img src={logo} alt="logo" className="w-9" />
          <p className="text-lg">Quick Bill</p>
        </div>
      </div>
      <div className="flex items-center px-5 justify-around w-full">
        <div className="flex items-center p-2 ">
          <span className="px-5 text-md bg-[#8080801e] rounded-l-lg h-10 flex items-center">
            <BsSearch />
          </span>
          <input
            type="text"
            placeholder="search"
            className="bg-[#8080801e] border-0  rounded-r-lg h-10 !text-[grey] text-xl pr-5"
          />
        </div>
        <div className="flex items-center md:hidden ">
          {NAVBAR_LINKS.map((link) => (
            <NavButton className=" text-sm " key={link.text} {...link} />
          ))}
        </div>
        <div className="buttonNav flex items-center gap-5">
          <div className="flex items-center">
            <div className="cursor-pointer">
              <img className="h-[50px]" src={RestaurantLogo} alt="" />
            </div>
          </div>
          <div
            onClick={() => logoutCall()}
            className="cursor-pointer hover:bg-gray-100 w-12 h-12 flex justify-center items-center rounded-xl"
          >
            <MdExitToApp className="text-3xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
