import { createContext } from 'react';
import { MenuItem } from 'requests/MenuItems/types';
import { Ticket } from 'requests/Tickets/types';
import { PaymentIntent, Restaurant, Table, User } from 'requests/types';

interface CreateContextInterface {
  user: User | null;
  setUser: (user: User | null) => void;
  paymentIntent: PaymentIntent | null;
  setPaymentIntent: (paymentIntent: PaymentIntent | null) => void;
  items: MenuItem[] | null;
  setItems: (items: MenuItem[] | null) => void;
  restaurant: Restaurant | null;
  setRestaurant: (restaurant: any | null) => void;
  setCurrentTable: (Table: any | null) => void;
  currentTable: Table | null;
  setCurrentOrder: (Table: any | null) => void;
  currentOrder: Ticket | null;
  firstOpen: boolean
  setFirstOpen: (firstOpen: any) => void
}
export default createContext<CreateContextInterface>({
  user: null,
  paymentIntent: null,
  setPaymentIntent: () => { },
  setUser: () => { },
  items: null,
  setItems: () => { },
  restaurant: null,
  setRestaurant: () => { },
  setCurrentTable: () => { },
  currentTable: null,
  currentOrder: null,
  setCurrentOrder: () => { },
  firstOpen: true,
  setFirstOpen: () => { },
});
