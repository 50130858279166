import { Link } from 'react-router-dom';

interface NavButtonInterface {
  path: string;
  text: string;
  className?: string;
}

const NavButton = ({ path, text, className }: NavButtonInterface) => (
  <Link to={path} className={`px-4  !text-[grey] ${className} font-PopinsMedium  `}>
    {text}
  </Link>
);

NavButton.defaultProps = {
  className: '',
};
export default NavButton;
