import chooseItem from 'assets/choose.png';
import payAll from 'assets/all.png';
import splitItem from 'assets/split.png';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import rootContext from 'contexts/rootContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

export default function ChooseOrderPWA() {
  const { items, setPaymentIntent } = useContext(rootContext);
  const payAllFunction = () => {
    setPaymentIntent({
      currency: 'usd',
      amount: Number(Number(items?.reduce((acc: number, item: any) => acc + Number(item.price * item.quantity), 0))),
      payment_method_types: ['card'],
    });
  };

  return (
    <div className="h-[100vh] flex flex-col px-4 gap-2 font-PopinsMedium">
      <Header title="How to pay" />
      <div className="h-full justify-center flex flex-col">
        <div className="h-full justify-between flex flex-col max-h-[600px]">
          <div className="flex justify-evenly h-full flex-col">
            <div className="flex items-center justify-center gap-6">
              <div className=" p-5 border-gray-100 shadow-md rounded-xl w-[100px] h-[100px] flex justify-center items-center">
                <img src={payAll} alt="" />
              </div>
              <Link to={{ pathname: '/tipspwa' }}>
                <CustomBtn
                  onClick={() => {
                    payAllFunction();
                  }}
                  text="Pay all"
                  className="!bg-white hover:border-none !min-w-[190px] border-none shadow-md  flex !justify-center border border-grey rounded-2xl py-[22px] hover:!bg-grey hover:!text-white !m-0"
                />
              </Link>
            </div>
            <div className="flex  items-center justify-center gap-6">
              <div className="  border-gray-100 shadow-md rounded-xl w-[100px] h-[100px] flex justify-center items-center">
                <img src={splitItem} alt="" />
              </div>
              <Link to={{ pathname: '/split' }}>
                <CustomBtn
                  text="Split the bill"
                  className="!bg-white  border-none shadow-md hover:border-none !min-w-[190px] flex !justify-center border border-grey rounded-2xl py-[22px] hover:!bg-grey hover:!text-white !m-0"
                />
              </Link>
            </div>

            <div className="flex items-center justify-center gap-6">
              <div className=" p-5 border-gray-100 shadow-md rounded-xl w-[100px] h-[100px] flex justify-center items-center">
                <img src={chooseItem} alt="" />
              </div>
              <Link to={{ pathname: '/chooseitemspwa' }}>
                <CustomBtn
                  text="Choose your items"
                  className="!bg-white  border-none shadow-md hover:border-none min-w-[190px]  max-w-[190px] flex !justify-center border border-grey rounded-2xl py-[22px] hover:!bg-grey hover:!text-white !m-0 !px-0"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
