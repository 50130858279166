/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable global-require */
/* eslint-disable react/jsx-no-bind */
import Footer from 'components/ui/FooterPWA/Footer';
import Header from 'components/ui/HeaderPWA/Header';
import Loading from 'components/ui/Loading';
import { NoIdTAble } from 'components/ui/Route/Route';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useGetPointSaleByParams } from 'requests/PointSale/request';

export default function MenuLayout() {
  const [, setNumPages] = useState(1);
  const [menu2Display, setmenu2Display] = useState<string>('');

  const idTable = localStorage.getItem('idTable');
  if (!idTable) {
    return <NoIdTAble />;
  }

  function onDocumentLoadSuccess(numPage: any) {
    setNumPages(numPage);
  }
  const { data, loading } = useGetPointSaleByParams({
    variables: { idTable },
    skip: !idTable,
    ssr: true,
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (!loading) {
      setmenu2Display(`https://quickbill-data.s3.me-south-1.amazonaws.com/${data?.getPointByParams?.menus[0]?.image}`);
    }
  }, [loading]);
  return (
    <div className="h-[100vh] flex flex-col px-4 overflow-hidden bg-gray-100">
      <Header title="Menu" />
      <div className="h-full flex  justify-center max-h-[calc(100vh)]">
        <div className=" flex flex-col gap-4 h-full w-full">
          <ul className="flex flex-row gap-6 text-sm justify-center cursor-pointer font-PopinsMedium">
            {loading ? (
              <Loading />
            ) : (
              data?.getPointByParams?.menus
                ?.filter((menu) => String(menu.visible) === 'true')
                .sort((a, b) => (Number(a.order) < Number(b.order) ? -1 : 1))
                .map((menu) => {
                  return menu;
                })
                .map((item, index) => (
                  <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={`menu${index}`}
                    onClick={() => {
                      setmenu2Display(`https://quickbill-data.s3.me-south-1.amazonaws.com/${item?.image}`);
                    }}
                    className=" cursor-pointer decoration-2 text-warmGray-400"
                  >
                    {item?.category}
                  </li>
                ))
            )}
          </ul>
          <div className="bg-white border-[1px] rounded-2xl border-gray-100 shadow-xl font-PopinsMedium overflow-y-auto">
            {loading ? (
              <div className="flex items-center justify-center">
                <Loading />
              </div>
            ) : (
              <Document
                file={menu2Display}
                loading={
                  <div className="flex items-center justify-center">
                    <Loading />
                  </div>
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={1} />
              </Document>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
