import React from 'react';
import logo from '../../../assets/logo3.png';

export default function Footer() {
  return (
    <div className="mt-auto py-4 flex justify-center">
      <img src={logo} className=" h-[20px]" alt="logo" />
    </div>
  );
}
