import GooglePayButton from '@google-pay/button-react';
import { useHistory } from 'react-router-dom';

export default function GooglePayBTN({ amount, ...props }: any) {
  const history = useHistory();

  return (
    <GooglePayButton
      buttonSizeMode="fill"
      environment="TEST"
      buttonType="short"
      buttonColor="white"
      style={{ height: 62 }}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'example',
                gatewayMerchantId: 'exampleGatewayMerchantId',
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: '12345678901234567890',
          merchantName: 'Demo Merchant',
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: '12',
          currencyCode: 'AED',
          countryCode: 'AE',
        },
      }}
      onLoadPaymentData={(paymentRequest: any) => {
        if (paymentRequest) {
          history.push('/done');
        }
      }}
      {...props}
    />
  );
}
