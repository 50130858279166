import { IconType } from 'react-icons/lib';
import Loading from '../Loading';

interface Props {
  Icon?: IconType;
  text?: string;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  loading?: boolean;
  loadingColor?: string;
}

export default function CustomBtn({
  Icon,
  loading,
  text,
  onClick,
  className,
  type,
  disabled,
  loadingColor = 'black',
}: Props) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`flex gap-2 items-center min-w-auto h-12 m-2 p-2 rounded-lg  hover:border-gray-400 text-black  duration-500 ease-in-out justify-center font-medium ${className}`}
    >
      {Icon && (
        <div className=" flex justify-center">
          <Icon className="text-[20px]" />
        </div>
      )}
      {text && (
        <p className={`duration-200 flex items-center    ${Icon ? 'text-left' : 'text-center'}`}>
          {loading ? <Loading color={loadingColor} size="sm" /> : text}
        </p>
      )}
    </button>
  );
}
CustomBtn.defaultProps = {
  text: undefined,
  Icon: undefined,
  onClick: () => {},
  className: '',
};
